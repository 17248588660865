<template>
  <div>
    <el-card>
      <div class="title">落地页</div>
      <el-form ref="form" :inline="true">
        <el-form-item label="分类">
          <el-select
            v-model="queryData.type"
            placeholder="请选择分类"
            clearable
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in landPageType"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间">
          <el-date-picker
            type="datetimerange"
            v-model="times"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="toQuery" icon="el-icon-search"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item class="generate">
          <el-button type="primary" @click="toAdd"
            >添加落地页</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        :data="landPageList"
        stripe
        class="table"
        ref="userTable.multipleSelection"
        header-cell-class-name="table-header"
        border
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column label="标题" prop="title" align="center"></el-table-column>
        <el-table-column label="分类" prop="type" align="center">
        </el-table-column>
        <el-table-column label="创建时间" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.create_time !== null">
              {{
                $moment(scope.row.create_time * 1000).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
            </div>
            <div v-else>空</div>
          </template>
        </el-table-column>
        <el-table-column label="更新时间" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.update_time !== null">
              {{
                $moment(scope.row.update_time * 1000).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
            </div>
            <div v-else>空</div>
          </template>
        </el-table-column>
       

        <el-table-column label="操作" align="center" >
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              @click="toEdit(scope.row.type)"
              size="small"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="toRemove(scope.row.type)"
              size="small"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>

       <!-- 分页区域 -->
     <div class="pagination">
       <span></span>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryData.page"
        :page-sizes="[3, 5, 8, 10]"
        :page-size="queryData.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        algin:="center"
      ></el-pagination>
     </div>

      <!-- 添加落地页 -->
      <el-dialog :title="addtitle" :visible.sync="addDialog" :center=true width="40%" @close="closed" :close-on-click-modal=false>
      <el-form :model="addForm"  ref="addForm">
        <el-form-item label="分类" label-width="100px">
          <el-select
            v-model="addForm.type"
            placeholder="请选择分类"
            clearable
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in landPageType"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item el-form-item label="标题" label-width="100px">
            <el-input v-model="addForm.title" placeholder="请输入标题"></el-input>
        </el-form-item>
        <el-form-item el-form-item label="内容：" label-width="100px">
             <quill-editor
              v-model="addForm.content"
              ref="myQuillEditor"
            >
            </quill-editor>
        </el-form-item>

        <el-form-item label-width=40% style="margin-top: 20px;">
           <el-button type="primary" v-if="isAdd=='add'" @click="addNow" >立即添加</el-button>
           <el-button type="primary" v-else @click="editNow">立即修改</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    </el-card>
  </div>
</template>

<script>
import store from "@/store";

//引入富文本组件
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import {LandPageList,LandPageType,LandPageAdd,LandPageDel,LandPageDetails,LandPageEdit} from '@/api/land_page.js'

import { warningDialog } from "@/utils/dialog";
var COS = require('cos-js-sdk-v5');
import { log } from 'util';

export default {
  components: {
     quillEditor
  },
  data() {
    return {
        queryData:{
          type:null,
          page:1,
          limit:10,
          start_time:null,
          end_time:null
        },
        times:null,
        total:0,
        landPageList:[],
        landPageType:[],
        isAdd:'add',
        addtitle:'',
        addDialog:false,
        addForm:{
            title:'',
            type:'',
            content:''
        },

    };
  },

  created(){
    this.getLandPageList()
    this.getLandPageType()
  },
  methods: {
    // 获取落地页列表
    getLandPageList(){
        LandPageList(this.queryData).then(res=>{
            if (res.code!==1) {
              this.$message.error(res.msg)
              return
            }
            this.total=res.data.total
            this.landPageList=res.data.data
        })
    },

    // 获取落地页分类
    getLandPageType(){
        LandPageType().then(res=>{
            if (res.code!==1) {
              this.$message.error(res.msg)
              return
            }
            this.landPageType=res.data
        })
    },

    // 添加弹窗
    toAdd(){
      this.isAdd='add'
      this.addtitle='添加落地页'
      this.addDialog = true
    },

    // 添加语法
    addNow(){
        if (this.addForm.type=='') {
            this.$message.error('请选择分类')
            return
        }else if (this.addForm.title=='') {
            this.$message.error('请填写标题')
            return
        }else if (this.addForm.content=='') {
            this.$message.error('请填写内容')
            return
        }
        LandPageAdd(this.addForm).then(res=>{
            if (res.code!==1) {
                this.$message.error(res.msg)
                return
            }
            this.$message.success('添加落地页成功')
            this.addDialog=false
            this.getLandPageList()
        })
    },

    // 删除落地页
    async  toRemove(type){
        const result = await this.$confirm("是否要删除该落地页?", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).catch(err => err);
      if (result !== "confirm") return this.$message.info("已经取消删除");
        LandPageDel({type:type}).then(res=>{
            if (res.code!==1) {
                this.$message.error(res.msg)
                return
            }
            this.$message.success('删除落地成功')
            this.getLandPageList()
        })
    },

    // 点击编辑图标
    toEdit(type){
        this.isAdd='edit'
        this.addtitle='编辑落地页'
        this.addDialog = true
        LandPageDetails({type:type}).then(res=>{
             if (res.code!==1) {
                this.$message.error(res.msg)
                return
            }
            this.addForm=res.data
        })
    },

    // 点击立即修改
    editNow(){
        if (this.addForm.type=='') {
            this.$message.error('请选择分类')
            return
        }else if (this.addForm.title=='') {
            this.$message.error('请填写标题')
            return
        }else if (this.addForm.content=='') {
            this.$message.error('请填写内容')
            return
        }
        LandPageEdit(this.addForm).then(res=>{
            if (res.code!==1) {
                this.$message.error(res.msg)
                return
            }
            this.$message.success('修改落地成功!')
            this.addDialog=false
            this.getLandPageList()
        })
    },

     //  查询
    toQuery(){
        if (this.times) {
            this.queryData.start_time = new Date(this.times[0]).getTime() / 1000;
            this.queryData.end_time = new Date(this.times[1]).getTime() / 1000;
        }else if(this.times==null){
            this.queryData.start_time =null;
            this.queryData.end_time =null;
        }
        this.getLandPageList()
    },
    handleSizeChange(size) {
        this.queryData.limit = size;
        this.getLandPageList();
    },
    handleCurrentChange(page) {
        this.queryData.page = page;
        this.getLandPageList();
    },
    // 添加落地页弹窗关闭事件
    closed(){
      this.addForm={}
    },
  }
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}

.add {
  position: fixed;
  /*align-self: flex-end;*/
  right: 130px;
}

.refresh {
  /* position: fixed; */
  /*align-self: flex-end;*/
  /* right: 80px; */
  float: right;
}

.edit {
  margin-left: 50px;
  width: 100px;
  margin-top: 30px;
}

.editUserItemLeft {
  padding-left: 15px;
  margin-top: 5px;
}

.editUserItem {
  margin-bottom: 10px;
  padding-left: 15px;
}
.el-tag {
  cursor: pointer;
}
.pagination {
  display: flex;
  justify-content: space-between;
}
.detail-box{
  height: 750px;
}
.detail{
  width: 100%;
  height: 80px;
  display: flex;
  margin-bottom: 30px;
}
.detail .detail-img{
  flex: 2;
}
.detail-img img{
  width: 82px;
  height: 82px;
  border-radius: 50%;
}
.detail .detail-one{
  flex: 4;
}
.detail .detail-two{
  flex: 5;
}
.detail .detail-three{
  flex: 3;
}
.detail p{
  line-height: 28px;
  color: #323232;
  font-size: 16px;
  font-weight: 500;
}
.detail-box .tabs{
  border-bottom: 2px solid #38A28A;
}
.detail-box .tabs .el-button{
  width: 160px;
  height: 50px;
  border-radius: 0;
}
.detail-box .detail-content{
  height: 504px;
  background-color: #F0F0F0;
}
.jurisdiction{
  height: 700px;
  border-top: 1px solid #ccc;
}
.jurisdiction .jurisdiction-content{
  height: 600px;
  overflow: auto;
}
.jurisdiction .jurisdiction-confirm{
  text-align: center;
  height:100px;
  line-height: 100px;
  margin: auto;
  border-top: 1px solid #ccc;
}
.jurisdiction .jurisdiction-confirm .el-button{
  width: 90px;
  height: 40px;
}
.desc{
    width: 180px;
    height: 112px;
    overflow: hidden;
}
.desc img{
  width: 100%;
  height: 100%;
}
.generate {
  float: right;
}
.file-name{
  /* display: inline-block; */
  width: 100%;
  margin-left: 10px;
  margin-right: 20px;
  white-space: nowrap;
	overflow: hidden;
}
.imgSelect img{
  width: 300px;
  height: 200px;
  cursor: pointer;
  
}
.videoSelect video{
  width: 300px;
  height: 200px;
  cursor: pointer;
  
}
::v-deep .el-pager li.active{
  color: #FF6688;
}

.title_box{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
}

::v-deep .ql-editor{
  min-height: 400px;
}
</style>
