import request from "@/utils/request";


// 获取落地页列表
export function LandPageList(params){
    return request({
        url:'/admin/base/landPageList',
        method:'get',
        params
        }
    )
}

// 获取落地页分类列表
export function LandPageType(params){
    return request({
        url:'/admin/base/landPageType',
        method:'get',
        params
        }
    )
}

// 添加落地页
export function LandPageAdd(data){
    return request({
        url:'/admin/base/landPageAdd',
        method:'post',
        data
        }
    )
}
// 添加落地页
export function LandPageDel(data){
    return request({
        url:'/admin/base/landPageDel',
        method:'delete',
        data
        }
    )
}

// 获取落地页详情
export function LandPageDetails(params){
    return request({
        url:'/admin/base/landPageDetails',
        method:'get',
        params
        }
    )
}

// 编辑落地页
export function LandPageEdit(data){
    return request({
        url:'/admin/base/landPageEdit',
        method:'put',
        data
        }
    )
}